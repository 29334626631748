/* eslint-disable no-underscore-dangle */
import { memoize } from 'underscore';
import { createBrowserHistory as createHistory } from 'history';
import { createOafRouter, defaultSettings } from 'oaf-routing';
import { locale } from './i18n';
import store from '../redux/store';

const basename = process.env.JEST_WORKER_ID ? '' : `/${locale()}`;
const history = createHistory({ basename });

let _mainContextPath;
let _prevMainContextPath;

const mainContextPath = () => _mainContextPath;
const prevMainContextPath = () => _prevMainContextPath;

const setMainContextPath = (fragment) => {
  _prevMainContextPath = _mainContextPath;
  _mainContextPath = fragment;
};

const modalRegexes = [
  /^account\/code_reset(?:\/([^/?]+))?(?:\?([\s\S]*))?$/, // React routed
  /^account\/verify\/sow_collection/, // React routed
  /^account\/password_reset(?:\?([\s\S]*))?$/, // React routed
  /^account\/password_reset\/send_sms_code(?:\?([\s\S]*))?$/, // React routed
  /^account\/password_change(?:\?([\s\S]*))?$/, // React routed
  /^account\/new(?:\?([\s\S]*))?$/, // React routed
  /^account\/offers/, // React routed
  /^bv_cashier\/lottery\/([^/?]+)([^/?]+)(?:\?([\s\S]*))?$/, // React routed
  /^bv_cashier\/lottery\/([^/?]+)(?:\?([\s\S]*))?$/, // React routed
  /^bv_cashier\/payments(?:([^?]*?))?(?:\?([\s\S]*))?$/, // React routed
  /^bv_cashier\/withdraw(?:([^?]*?))?(?:\?([\s\S]*))?$/, // React routed
  /^bv_cashier\/deposit(?:([^?]*?))?(?:\?([\s\S]*))?$/, // React routed
  /^bv_cashier\/history(?:\/([^/?]+))?(?:\?([\s\S]*))?$/, // React routed
  /^bv_cashier\/overview(?:\?([\s\S]*))?$/, // React routed
  /^account(?:\?([\s\S]*))?$/, // React routed
  /^feedback(?:\?([\s\S]*))?$/, // React routed
  /^preferences\/twofactorauth(\/\w+)?(?:\?([\s\S]*))?$/, // React routed
  /^preferences\/complete_mobile_verification(?:\?([\s\S]*))?$/, // React routed
  /^preferences\/edit(?:\?([\s\S]*))?$/, // React routed
  /^quick_deposit/, // React routed
  /^refer_a_friend(?:\?([\s\S]*))?$/, // React routed
  /^login(?:\?([\s\S]*))?$/, // React routed
  /^twofactorauth\/login(\/geo)?(?:\?([\s\S]*))?$/, // React routed
  /^customer_inbox(?:\?([\s\S]*))?$/, // React routed
  /^account\/verification_zone(\/\w+)?(?:\?([\s\S]*))?$/, // React routed
  /^pages\/contact-us(?:\?([\s\S]*))?$/, // React routed
  /^pages\/request-callback(?:\?([\s\S]*))?$/, // React routed
  /^safer_gambling\/questionnaire/, // React routed
  /^safer_gambling\/portal/, // React routed
  /^personal_details/, // React routed
  /^sports\/1000\/timetable\/.+/, // React routed
  /^email_verification/,
  /^cashout/,
  /^betslip/, // React routed
  /^my_transactions(?:\/([^/?]+))?(?:\?([\s\S]*))?$/,
  /^search(?:\?([\s\S]*))?$/, // React routed
  /^account\/verify\/refresh/, // React routed
];

// Remove leading slash, react-router uses paths, I prefer not to change all regexes now
const isModal = memoize((fragment) => modalRegexes.some((modalRegex) => modalRegex.test(fragment.replace(/^\//, ''))));

const longestCommonPrefix = (strs) => {
  let prefix = '';
  if (strs === null || strs.length === 0) return prefix;

  for (let i = 0; i < strs[0].length; i += 1) {
    const char = strs[0][i]; // loop through all characters of the very first string.

    for (let j = 1; j < strs.length; j += 1) {
      // loop through all other strings in the array
      if (strs[j][i] !== char) return prefix;
    }
    prefix += char;
  }

  return prefix;
};

// Exceptions to scroll up when navigating through the subroutes
const noScrollToTop = [
  [/\/sports\/\d+\/meetings\/\d+\/events\/\d+\?market_group=(\d*)/, /\d+/], // event-level coupon groups
  [/\/sports\/\d+\?tab=(\d*)/, /\d+/], // sport home tabs
  [/\/(live-)?casino\/category\//, /\w+/], // casino categories
];

const isCasinoSearch = ({ prevUrl, nextUrl, prefix }) => {
  const regex = /\/search(?:\?([\s\S]*))?$/;
  return regex.test(nextUrl) || (regex.test(prevUrl) && prefix.length > 1);
};

const shouldHandleAction = (prevLocation, nextLocation/* , action */) => {
  const prevUrl = `${prevLocation.pathname}${prevLocation.search}`;
  const nextUrl = `${nextLocation.pathname}${nextLocation.search}`;

  const prefix = longestCommonPrefix([prevUrl, nextUrl]);
  const newSuffix = nextUrl.slice(prefix.length);

  // modals never need scrolling up
  if (isModal(nextLocation.pathname) || isModal(prevLocation.pathname)) return false;
  // casino search is hard to detect by paths because heartbingo
  // can have any category from the CMS in the url
  if (isCasinoSearch({ prevUrl, nextUrl, prefix })) return false;
  // various path transition exceptions
  if (noScrollToTop.some(([common, diff]) => (common.test(prefix) && diff.test(newSuffix)))) {
    return false;
  }

  return true;
};

const settings = {
  ...defaultSettings,
  shouldHandleAction,
  announcePageNavigation: false,
  primaryFocusTarget: '#root > .center-content',
};

const oafRouter = createOafRouter(settings, (location) => location.hash);
const initialLocation = history.location;

// HACK: We use setTimeout to give React a chance to render before we repair focus.
setTimeout(() => {
  oafRouter.handleFirstPageLoad(initialLocation);
}, settings.renderTimeout);

let previousLocation = initialLocation;

history.listen((newLocation, action) => {
  // We're the first subscribed listener, so the DOM won't have been updated yet.
  oafRouter.handleLocationWillChange(
    previousLocation.key,
    newLocation.key,
    action,
  );

  // HACK: We use setTimeout to give React a chance to render before we repair focus.
  const stablePreviousLocation = previousLocation;
  setTimeout(() => {
    oafRouter.handleLocationChanged(
      stablePreviousLocation,
      newLocation,
      newLocation.key,
      action,
    );
  }, settings.renderTimeout);

  previousLocation = newLocation;
});

const refresh = () => {
  const { location } = history;
  const fragment = location.pathname + location.search;

  // Keep fresh the main context path and previous
  // Previous useful for main context pages which mimics modals behaviour
  // But can open other modals at the same time (i.e. desktop game launch)
  if (!isModal(fragment) && fragment !== _mainContextPath) {
    setMainContextPath(fragment);
  }

  store.actionDispatchers.routing.refreshCurrentPath();
};

export {
  refresh,
  mainContextPath,
  prevMainContextPath,
  setMainContextPath,
  isModal,
  history,
};
